// src/App.tsx
import React, { useEffect, useState, useCallback } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import DefiAdmin from './components/DefiAdmin';
import DefiMap from './components/DefiMap';
import AccountLogin from './components/AccountLogin';
import DefiCheckField from './components/DefiCheckField';
import LostAndFound from './components/LostAndFound';
import Registration from './components/Registration';
import DefiFinder from './components/DefiFinder';
import CryptoJS from 'crypto-js';
import AlarmList from './components/AlarmList';
import { MapCenterContext } from './components/MapContext';
import DefiWizardContext, { DefiWizardProvider } from './components/DefiWizardContext';
import PrivacyPolicy from './components/PrivacyPolicy';
import LanguageSwitcher from './components/LanguageSwitcher'; // Import LanguageSwitcher
import { useTranslation } from 'react-i18next'; // Import useTranslation

export const apiUrl = '/api';

const App: React.FC = () => {
  const { t } = useTranslation(); // Initialize translation
  const [currentTime, setCurrentTime] = useState(new Date());
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [logo, setLogo] = useState('');
  const [mapCenter, setMapCenter] = useState<[number, number]>([0, 0]);
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const protocol = window.location.protocol;
  const host = window.location.host;
  const baseUrl = `${protocol}//${host}`;
  const phoneNumber = localStorage.getItem('phoneNumber') || '';
  const locationEnabled = localStorage.getItem('locationEnabled') === 'true';

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });
  }, []);

  const handleLogin = async (email: string, password: string) => {
    const passwordHash = CryptoJS.MD5(password).toString();
    try {
      const response = await fetch(apiUrl + '/Account/LoginApi', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password: passwordHash }),
      });
      const data = await response.json();
      if (response.ok) {
        setEmail(email);
        setLoggedIn(true);
      } else {
        alert(t('loginFailed')); // Use translation
        setLoggedIn(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(`${t('error')}: ${error.message}`); // Use translation
      } else {
        alert(t('unknownError')); // Use translation
      }
      setLoggedIn(false);
    }
  };

  const handleLogoff = useCallback(() => {
    setLoggedIn(false);
    setEmail('');
    setLogo('');
    window.location.href = baseUrl + '/';
  }, [baseUrl]);

  useEffect(() => {
    const timerID = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => {
      clearInterval(timerID);
    };
  }, []);

  useEffect(() => {
    if (email) {
      fetch(apiUrl + '/Defis/GetLogo?email=' + email)
        .then(response => response.text())
        .then(data => data !== '' ? setLogo('data:image/png;base64,' + data) : setLogo(''));
    }
  }, [email]);

  return (
    <Router>
      <MapCenterContext.Provider value={{ mapCenter, setMapCenter }}>
        <div id="divMain">
          <div style={{ position: 'relative' }}>
            <img className="header-image" alt="Header" src={`${baseUrl}/images/logo_middle.png`} />
            {logo && logo !== '' &&
              <img
                src={logo}
                alt="User Logo"
                style={{ position: 'absolute', top: '10px', right: '10px', height: '50px' }}
              />
            }
          </div>

          <Navigation loggedIn={loggedIn} handleLogoff={handleLogoff} />

          <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent' }}>
              <LanguageSwitcher /> {/* No inline style applied here */}
            </div>
            <div id="clock" style={{ position: 'relative', top: '0px' }}>
              {currentTime.toLocaleTimeString()}
            </div>
          </div>






          {loggedIn && <AlarmList />}
          <Routes>
            <Route path="/" element={<DefiMap email={email} />} />
            <Route path="/admin" element={loggedIn ? <DefiWizardProvider><DefiAdmin email={email} /></DefiWizardProvider> : <AccountLogin onLogin={handleLogin} />} />
            <Route path="/login" element={<AccountLogin onLogin={handleLogin} />} />
            <Route path="/check" element={<DefiCheckField phoneNumber={phoneNumber} locationEnabled={locationEnabled} />} />
            <Route path="/finder" element={<DefiFinder />} />
            <Route path="/lostandfound/:id" element={<LostAndFound />} />
            <Route path="/registration/:id" element={<Registration />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          </Routes>

          <div className="footer" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: '15px', padding: '5px 0' }}>
            <div>Public AED Smart City Network</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={`${baseUrl}/images/logo_kiss.png`} alt="KISS IT Solutions logo" style={{ height: '70px', marginRight: '10px' }} />
              Developed by KISS IT Solutions | <a href="https://kiss-it.io" style={{ color: '#87CEFA' }}>kiss-it.io</a>
            </div>
          </div>
        </div>
      </MapCenterContext.Provider>
    </Router>
  );
}

const Navigation: React.FC<{ loggedIn: boolean, handleLogoff: () => void }> = ({ loggedIn, handleLogoff }) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation(); // Use translation in Navigation

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuItemClick = (path: string) => {
    navigate(path);
    setMenuOpen(false);
  };

  const handlePrivacyPolicyClick = () => {
    navigate('/privacypolicy');
    setMenuOpen(false);
  };

  return (
    <div className="nav">
      <div className="menu">
        <a className="nav-link menu-link" href="#" onClick={(e) => { e.preventDefault(); handleMenuToggle(); }}>
          <span className="menu-icon">&#9776;</span> {t('menu')}
        </a>
        {menuOpen && (
          <div className="dropdown-content">
            <a className="nav-link" href="#" onClick={(e) => { e.preventDefault(); handleMenuItemClick('/admin'); }}>🔒 {t('defiAdmin')}</a>
            <a className="nav-link" href="#" onClick={(e) => { e.preventDefault(); handleMenuItemClick('/'); }}>🗺 {t('defiMap')}</a>
            <a className="nav-link" href="#" onClick={(e) => { e.preventDefault(); handleMenuItemClick('/check'); }}>{t('defiCheck')}</a>
            <a className="nav-link" href="#" onClick={(e) => { e.preventDefault(); handleMenuItemClick('/finder'); }}>{t('defiFinder')}</a>
            <a className="nav-link" href="#" onClick={(e) => { e.preventDefault(); loggedIn ? handleLogoff() : handleMenuItemClick('/login'); }}>
              {loggedIn ? t('logout') : t('login')}
            </a>
            <a className="nav-link" href="#" onClick={(e) => { e.preventDefault(); handlePrivacyPolicyClick(); }}>{t('privacyPolicy')}</a>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
