import React, { ChangeEvent, useState, useEffect } from 'react';
import { apiUrl } from '../App';

interface Location {
  id: number;
  name?: string;
  operatorName?: string;
  vendorName?: string;
  street?: string;
  houseNumber?: string;
  city?: string;
  postalCode?: string;
}

interface SurveyProps {
  sessionId: string;
  address: string;
  location: { lat: string; lon: string };
  currentStep: 'requestCode' | 'verifyCode' | 'locationPermission' | 'enterAddress' | 'confirmLocation' | 'uploadPhotos' | 'submitted';
  setCurrentStep: React.Dispatch<React.SetStateAction<'requestCode' | 'verifyCode' | 'locationPermission' | 'enterAddress' | 'confirmLocation' | 'uploadPhotos' | 'submitted'>>;
  setStatusMessage: (message: string) => void;
  selectedLocation: Location | null;
  isNewLocation: boolean;
  isLocationChanged: boolean;
}

type Day = 'Mo' | 'Di' | 'Mi' | 'Do' | 'Fr' | 'Sa' | 'So';

interface TimeRange {
  open: string;
  close: string;
  pauseStart?: string;
  pauseEnd?: string;
  days: Day[];
}

const Survey: React.FC<SurveyProps> = ({ sessionId, address, location, currentStep, setCurrentStep, setStatusMessage, selectedLocation, isNewLocation, isLocationChanged }) => {
  const [photos, setPhotos] = useState<File[]>([]);
  const [comments, setComments] = useState('');
  const [questionAnswer, setQuestionAnswer] = useState({
    accessible: 'No',
    visibleDamage: 'No',
    padsAvailable: 'No',
    batteryIndicator: 'No',
    cabinetLocked: 'No',
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [accessibility, setAccessibility] = useState<'24/7' | 'Limited'>('24/7');
  const [timeRanges, setTimeRanges] = useState<TimeRange[]>([]);
  const [newTimeRange, setNewTimeRange] = useState<TimeRange>({
    open: '',
    close: '',
    pauseStart: '',
    pauseEnd: '',
    days: [],
  });
  const [isPauseEnabled, setIsPauseEnabled] = useState(false);

  const style: { [key: string]: React.CSSProperties } = {
    container: {
      padding: '20px',
      fontFamily: 'Arial',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '66vh',
      alignItems: 'center',
      justifyContent: 'center'
    },
    questionBlock: {
      width: '100%',
      maxWidth: '500px',
      marginBottom: '30px',
      textAlign: 'center',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '20px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    image: {
      width: '100%',
      maxWidth: '300px',
      marginBottom: '20px'
    },
    input: {
      margin: '10px 0',
      padding: '10px',
      width: '100%',
      maxWidth: '300px',
      boxSizing: 'border-box'
    },
    button: {
      padding: '10px 20px',
      margin: '10px',
      cursor: 'pointer',
      width: '100%',
      maxWidth: '200px',
      boxSizing: 'border-box'
    },
    label: { display: 'block', marginTop: '20px' },
    hiddenInput: {
      display: 'none'
    },
    photoContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginTop: '10px'
    },
    photoItem: {
      position: 'relative',
      margin: '10px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      overflow: 'hidden',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    photoImage: {
      maxWidth: '100px',
      maxHeight: '100px',
      display: 'block'
    },
    removeButton: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      backgroundColor: 'rgba(255, 0, 0, 0.7)',
      color: 'white',
      border: 'none',
      borderRadius: '50%',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  };

  const handleUploadPhotos = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setPhotos((prevPhotos) => [...prevPhotos, ...Array.from(files)]);
    }
  };

  const handleRemovePhoto = (index: number) => {
    setPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    console.log('Session ID:', sessionId);

    // Convert photos to Base64 strings
    const imagePromises = photos.map(photo => {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(photo);
      });
    });

    let imagesBase64: string[];
    try {
        imagesBase64 = await Promise.all(imagePromises);
    } catch (error) {
        console.error('Error reading photos:', error);
        setStatusMessage('Fehlgeschlagen beim Lesen der Fotos. Bitte versuchen Sie es erneut.');
        return;
    }

    // Prepare the Survey object ensuring all values are strings
    const surveyFields: { [key: string]: string } = {
        comments: comments,
        accessible: questionAnswer.accessible,
        visibleDamage: questionAnswer.visibleDamage,
        padsAvailable: questionAnswer.padsAvailable,
        batteryIndicator: questionAnswer.batteryIndicator,
        cabinetLocked: questionAnswer.cabinetLocked,
        address: address,
        location: `${location.lat},${location.lon}`,
        isNewLocation: isNewLocation.toString(),
        isLocationChanged: isLocationChanged.toString(),
    };

    // Add initialLocation and currentLocation as JSON strings if available
    if (selectedLocation) {
        surveyFields.initialLocation = JSON.stringify({
            name: selectedLocation.name,
            street: selectedLocation.street,
            houseNumber: selectedLocation.houseNumber,
            city: selectedLocation.city,
            postalCode: selectedLocation.postalCode,
        });
        surveyFields.currentLocation = JSON.stringify({
            name: selectedLocation.name,
            street: selectedLocation.street,
            houseNumber: selectedLocation.houseNumber,
            city: selectedLocation.city,
            postalCode: selectedLocation.postalCode,
        });
    } else {
        surveyFields.initialLocation = "";
        surveyFields.currentLocation = "";
    }

    const surveyData = {
        IdAuthCode: parseInt(sessionId, 10),
        Survey: surveyFields,
        Img: imagesBase64.join(',')  // Combine images into a single string
    };

    console.log('Survey Data:', surveyData);

    try {
      const response = await fetch(`${apiUrl}/Defis/SubmitSurvey`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(surveyData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit survey');
      }

      const result = await response.json();
      console.log('Submission successful:', result.message);
      setCurrentStep('submitted');
      setStatusMessage('Erfolgreich eingereicht!');
    } catch (error) {
      console.error('Error submitting survey:', error);
      setStatusMessage('Fehlgeschlagen. Bitte versuchen Sie es erneut.');
    }
  };

  const handleRestartCheck = () => {
    if (currentStep === 'submitted') {
      setCurrentStep('locationPermission');
      setStatusMessage('Möchten Sie Ihren aktuellen Standort verwenden oder die Adresse manuell eingeben?');
    } else {
      if (window.confirm('Sind Sie sicher, dass Sie neu starten möchten? Alle eingegebenen Daten gehen verloren.')) {
        setCurrentStep('locationPermission');
        setStatusMessage('Möchten Sie Ihren aktuellen Standort verwenden oder die Adresse manuell eingeben?');
      }
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (currentStep !== 'submitted') {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [currentStep]);

  useEffect(() => {
    // Initialize newTimeRange with default values
    setNewTimeRange({
      open: '08:00',
      close: '17:00',
      pauseStart: '12:00',
      pauseEnd: '12:30',
      days: [],
    });
  }, []);

  const handleAddTimeRange = () => {
    console.log('Attempting to add time range:', newTimeRange);
    if (newTimeRange.open && newTimeRange.close && newTimeRange.days.length > 0) {
      const timeRangeToAdd = {
        ...newTimeRange,
        pauseStart: isPauseEnabled ? newTimeRange.pauseStart : undefined,
        pauseEnd: isPauseEnabled ? newTimeRange.pauseEnd : undefined,
      };

      setTimeRanges((prevTimeRanges) => {
        const updatedTimeRanges = [...prevTimeRanges, timeRangeToAdd];
        console.log('Updated time ranges:', updatedTimeRanges);
        return updatedTimeRanges;
      });

      setNewTimeRange({ open: '08:00', close: '17:00', pauseStart: '12:00', pauseEnd: '12:30', days: [] });
      setIsPauseEnabled(false); // Reset pause checkbox
    } else {
      console.log('Time range not added. Ensure all fields are filled and days are selected.');
    }
  };

  const handleRemoveTimeRange = (index: number) => {
    setTimeRanges(timeRanges.filter((_, i) => i !== index));
  };

  const handleTimeInputClick = (field: 'open' | 'close' | 'pauseStart' | 'pauseEnd') => {
    setNewTimeRange({ ...newTimeRange, [field]: '' });
  };

  return (
    <div style={style.container}>
      {currentStep !== 'submitted' ? (
        <>
          <div style={style.questionBlock}>
            <img src="https://defiapp.at/images/survey/001.jpg" alt="Is the site accessible?" style={style.image} />
            <label>Ist der AED öffentlich zugänglich?</label>
            <select
              value={accessibility}
              onChange={(e) => setAccessibility(e.target.value as '24/7' | 'Limited')}
              style={style.input}
            >
              <option value="24/7">24/7</option>
              <option value="Limited">Begrenzt</option>
            </select>

            {accessibility === 'Limited' && (
              <div style={{ marginBottom: '20px' }}>
                <div style={style.questionBlock}>
                  <label>Öffnungszeit:</label>
                  <input
                    type="time"
                    value={newTimeRange.open}
                    onChange={(e) => setNewTimeRange({ ...newTimeRange, open: e.target.value })}
                    onClick={() => handleTimeInputClick('open')}
                    style={style.input}
                  />
                  <label>Schließzeit:</label>
                  <input
                    type="time"
                    value={newTimeRange.close}
                    onChange={(e) => setNewTimeRange({ ...newTimeRange, close: e.target.value })}
                    onClick={() => handleTimeInputClick('close')}
                    style={style.input}
                  />
                  <label>
                    <input
                      type="checkbox"
                      checked={isPauseEnabled}
                      onChange={(e) => setIsPauseEnabled(e.target.checked)}
                      style={{ marginRight: '10px' }}
                    />
                    Pausezeiten festlegen
                  </label>
                  {isPauseEnabled && (
                    <>
                      <label>Pause Start:</label>
                      <input
                        type="time"
                        value={newTimeRange.pauseStart}
                        onChange={(e) => setNewTimeRange({ ...newTimeRange, pauseStart: e.target.value })}
                        onClick={() => handleTimeInputClick('pauseStart')}
                        style={style.input}
                      />
                      <label>Pause Ende:</label>
                      <input
                        type="time"
                        value={newTimeRange.pauseEnd}
                        onChange={(e) => setNewTimeRange({ ...newTimeRange, pauseEnd: e.target.value })}
                        onClick={() => handleTimeInputClick('pauseEnd')}
                        style={style.input}
                      />
                    </>
                  )}
                  <div>
                    {(['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'] as Day[]).map((day) => (
                      <label key={day} style={{ marginRight: '10px' }}>
                        <input
                          type="checkbox"
                          checked={newTimeRange.days.includes(day)}
                          onChange={(e) => {
                            const days = e.target.checked
                              ? [...newTimeRange.days, day]
                              : newTimeRange.days.filter((d) => d !== day);
                            setNewTimeRange({ ...newTimeRange, days });
                          }}
                        />
                        {day}
                      </label>
                    ))}
                  </div>
                  <button onClick={handleAddTimeRange} style={style.button}>
                    Zeitbereich hinzufügen
                  </button>
                </div>

                {timeRanges.map((range, index) => (
                  <div key={index} style={style.questionBlock}>
                    <p>
                      {range.days.join(', ')}: {range.open} - {range.close}
                      {range.pauseStart && range.pauseEnd && `, Pause: ${range.pauseStart} - ${range.pauseEnd}`}
                    </p>
                    <button onClick={() => handleRemoveTimeRange(index)} style={style.button}>
                      Entfernen
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div style={style.questionBlock}>
            <img src="https://defiapp.at/images/survey/002.jpg" alt="Is there visible damage?" style={style.image} />
            <label>Gibt es sichtbare Schäden? (J/N)</label>
            {/* <label>Is there visible damage? (Y/N)</label> */}
            <select
              value={questionAnswer.visibleDamage}
              onChange={(e) => setQuestionAnswer({ ...questionAnswer, visibleDamage: e.target.value })}
              style={style.input}
            >
              <option value="Yes">Ja</option>
              <option value="No">Nein</option>
            </select>
          </div>
          <div style={style.questionBlock}>
            <img src="https://defiapp.at/images/survey/003.jpg" alt="Are the pads available and in good condition?" style={style.image} />
            <label>Sind die Pads verfügbar und in gutem Zustand? (J/N)</label>
            {/* <label>Are the pads available and in good condition? (Y/N)</label> */}
            <select
              value={questionAnswer.padsAvailable}
              onChange={(e) => setQuestionAnswer({ ...questionAnswer, padsAvailable: e.target.value })}
              style={style.input}
            >
              <option value="Yes">Ja</option>
              <option value="No">Nein</option>
              <option value="NotCheckable">Nicht überprüfbar</option>
            </select>
          </div>
          <div style={style.questionBlock}>
            <img src="https://defiapp.at/images/survey/004.jpg" alt="Is the battery indicator showing sufficient charge?" style={style.image} />
            <label>Zeigt der Batterieanzeiger ausreichende Ladung an? (J/N)</label>
            {/* <label>Is the battery indicator showing sufficient charge? (Y/N)</label> */}
            <select
              value={questionAnswer.batteryIndicator}
              onChange={(e) => setQuestionAnswer({ ...questionAnswer, batteryIndicator: e.target.value })}
              style={style.input}
            >
              <option value="Yes">Ja</option>
              <option value="No">Nein</option>
              <option value="NotCheckable">Nicht überprüfbar</option>
            </select>
          </div>
          <div style={style.questionBlock}>
            <img src="https://defiapp.at/images/survey/005.jpg" alt="Is the cabinet locked and secure?" style={style.image} />
            <label>Ist der Schrank verschlossen und gesichert? (J/N)</label>
            {/* <label>Is the cabinet locked and secure? (Y/N)</label> */}
            <select
              value={questionAnswer.cabinetLocked}
              onChange={(e) => setQuestionAnswer({ ...questionAnswer, cabinetLocked: e.target.value })}
              style={style.input}
            >
              <option value="Yes">Ja</option>
              <option value="No">Nein</option>
              <option value="NoCabinet">Kein Kasten</option>
            </select>
          </div>
          <div style={style.questionBlock}>
            <img src="https://defiapp.at/images/survey/006.jpg" alt="Upload photos of the AED and its surroundings" style={style.image} />
            <label>Laden Sie Fotos des AED und seiner Umgebung hoch:</label>
            {/* <label>Upload photos of the AED and its surroundings:</label> */}
            <input
              type="file"
              multiple
              onChange={handleUploadPhotos}
              style={style.hiddenInput}
            />
            <button
              onClick={() => document.querySelector<HTMLInputElement>('input[type="file"]')?.click()}
              style={style.button}
            >
              Fotos hinzufügen
              {/* Add Photos */}
            </button>
            <div style={style.photoContainer}>
              {photos.map((photo, index) => (
                <div key={index} style={style.photoItem}>
                  <img src={URL.createObjectURL(photo)} alt={`Photo ${index + 1}`} style={style.photoImage} />
                  <button onClick={() => handleRemovePhoto(index)} style={style.removeButton}>
                    &times;
                    {/* Remove */}
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div style={style.questionBlock}>
            <img src="https://defiapp.at/images/survey/007.jpg" alt="Add any additional comments" style={style.image} />
            <label>Fügen Sie zusätzliche Kommentare hinzu:</label>
            {/* <label>Add any additional comments:</label> */}
            <input
              type="text"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder="Add comments"
              style={style.input}
            />
          </div>
        </>
      ) : (
        <div style={{
          ...style.questionBlock,
          textAlign: 'center',
          padding: '40px',
          marginBottom: '30px'
        }}>
          <h2 style={{ margin: '0 0 20px 0' }}>Vielen Dank für das Ausfüllen der Umfrage!</h2>
          <p style={{ margin: '0' }}>Erfolgreich eingereicht!</p>
        </div>
      )}
      
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        marginTop: '20px',
        width: '100%',
        maxWidth: '300px'
      }}>
        <button 
          onClick={() => {
            setIsProcessing(true);
            handleSubmit().finally(() => setIsProcessing(false));
          }}
          disabled={isProcessing || currentStep === 'submitted'}
          style={{
            ...style.button,
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            display: currentStep === 'submitted' ? 'none' : 'block',
            width: '100%'
          }}
        >
          Einreichen
        </button>
        <button
          onClick={handleRestartCheck}
          style={{
            ...style.button,
            backgroundColor: currentStep === 'submitted' ? '#4CAF50' : '#f44336',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            width: '100%'
          }}
        >
          {currentStep === 'submitted' ? 'Neue Defi-Prüfung starten' : 'Neu starten'}
        </button>
      </div>
    </div>
  );
};

export default Survey;
