import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    console.log(`Language changed to: ${lng}`); // Debug message
    setDropdownOpen(false);
  };

  return (
    <div style={{ position: 'relative', margin: '10px' }}>
      <button
        onClick={() => setDropdownOpen(!dropdownOpen)}
        style={{
          padding: '5px 10px',
          borderRadius: '5px',
          border: '1px solid #ccc',
          backgroundColor: 'transparent',
          cursor: 'pointer',
        }}
      >
        🌐 {/* Globe icon */}
      </button>
      {dropdownOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '5px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
            zIndex: 1000,
          }}
        >
          <button
            onClick={() => changeLanguage('en')}
            style={{
              display: 'block',
              padding: '5px 10px',
              width: '100%',
              textAlign: 'left',
              backgroundColor: i18n.language === 'en' ? '#87CEFA' : '#fff',
              color: i18n.language === 'en' ? '#fff' : '#000',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            English
          </button>
          <button
            onClick={() => changeLanguage('de')}
            style={{
              display: 'block',
              padding: '5px 10px',
              width: '100%',
              textAlign: 'left',
              backgroundColor: i18n.language === 'de' ? '#87CEFA' : '#fff',
              color: i18n.language === 'de' ? '#fff' : '#000',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            Deutsch
          </button>
        </div>
      )}
    </div>
  );
}

export default LanguageSwitcher;
