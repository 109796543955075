import React, { ChangeEvent, useEffect, useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../App';
import Survey from './Survey';  // Adjust the import path as needed

interface Location {
  id: number;
  name?: string;
  operatorName?: string;
  vendorName?: string;
  street?: string;
  houseNumber?: string;
  city?: string;
  postalCode?: string;
}

interface DefiCheckFieldProps {
  phoneNumber: string;
  locationEnabled: boolean;
}

const DefiCheckField: React.FC<DefiCheckFieldProps> = ({ phoneNumber: initialPhoneNumber, locationEnabled: initialLocationEnabled }) => {
  const [currentStep, setCurrentStep] = useState<'requestCode' | 'verifyCode' | 'locationPermission' | 'enterAddress' | 'confirmLocation' | 'uploadPhotos' | 'submitted'>('requestCode');
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber.replace(/^\+43/, '') || '');  // Ensure initial phone number doesn't duplicate country code
  const [verificationCode, setVerificationCode] = useState('');
  const [statusMessage, setStatusMessage] = useState('Geben Sie Ihre Telefonnummer ein (intl. Format z.B. +436641234567)');  // 'Enter your phone number (intl. format e.g. +436641234567)'
  const [sessionId, setSessionId] = useState(localStorage.getItem('sessionId') || '');
  const [location, setLocation] = useState({ lat: '', lon: '' });
  const [address, setAddress] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [nearestLocations, setNearestLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [isNewLocation, setIsNewLocation] = useState(false);
  const [initialLocation, setInitialLocation] = useState<Location | null>(null);
  const [isLocationChanged, setIsLocationChanged] = useState(false);
  const [comments, setComments] = useState('');
  const [questionAnswer, setQuestionAnswer] = useState({
    accessible: 'No',
    visibleDamage: 'No',
    padsAvailable: 'No',
    batteryIndicator: 'No',
    cabinetLocked: 'No',
  });
  const [imagesBase64, setImagesBase64] = useState<string[]>([]);
  const [addressQuery, setAddressQuery] = useState('');
  const [addressSuggestions, setAddressSuggestions] = useState<any[]>([]);

  const countryCode = '+43';

  // Ensure selectedLocation is initialized properly
  useEffect(() => {
    if (!selectedLocation) {
      setSelectedLocation({
        id: -1,
        name: '',
        street: '',
        houseNumber: '',
        city: '',
        postalCode: '',
      });
    }
  }, [selectedLocation]);

  const style: { [key: string]: React.CSSProperties } = {
    container: {
      padding: '20px',
      fontFamily: 'Arial',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '66vh',  // Ensure the container takes at least the height of the viewport
      alignItems: 'center',  // Center children horizontally
      justifyContent: 'center'  // Center children vertically
    },
    questionBlock: {
      width: '100%',
      maxWidth: '500px',
      marginBottom: '30px',
      textAlign: 'center',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '20px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    image: {
      width: '100%',
      maxWidth: '300px',
      marginBottom: '20px'
    },
    input: {
      margin: '10px 0',
      padding: '10px',
      width: '100%',
      maxWidth: '300px',
      boxSizing: 'border-box'
    },
    button: {
      padding: '10px 20px',
      margin: '10px',
      cursor: 'pointer',
      width: '100%',
      maxWidth: '200px',
      boxSizing: 'border-box'
    },
    label: { display: 'block', marginTop: '20px' },
    inputGroup: {
      display: 'flex',
      alignItems: 'center'
    },
    countryCode: {
      padding: '10px',
      margin: '10px 0 10px 10px',
      border: '1px solid #ccc',
      backgroundColor: '#eee',
      textAlign: 'center',
      width: '50px',
      userSelect: 'none',  // Prevent text selection
      pointerEvents: 'none',  // Disable interactions
      color: '#333'
    },
    authInfoContainer: {
      marginTop: '20px',
      textAlign: 'center',
      fontSize: '14px',
      color: '#666'
    },
    resetButton: {
      padding: '5px 10px',
      marginLeft: '10px',
      cursor: 'pointer',
      backgroundColor: '#bbb',
      color: 'white',
      border: 'none',
      borderRadius: '5px'
    },
    locationItem: {
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '5px',
      marginBottom: '10px',
      cursor: 'pointer',
      transition: 'background-color 0.3s'
    },
    locationItemHover: {
      backgroundColor: '#f0f0f0'
    }
  };

  useEffect(() => {
    if (sessionId) {
      if (initialLocationEnabled) {
        handleLocationPermission(true);
      } else {
        setCurrentStep('locationPermission');
        //setStatusMessage('Logged in successfully! Would you like to use your current location or enter address manually?');
        setStatusMessage('Erfolgreich eingeloggt! Möchten Sie Ihren aktuellen Standort verwenden oder die Adresse manuell eingeben?');

      }
    } else if (initialPhoneNumber) {
      setPhoneNumber(initialPhoneNumber.replace(/^\+43/, ''));
      setCurrentStep('verifyCode');
      //setStatusMessage('Please enter the code you received.');
      setStatusMessage('Bitte geben Sie den erhaltenen Code ein.');
    }
  }, [initialPhoneNumber, initialLocationEnabled, sessionId]);

  useEffect(() => {
    if (location.lat && location.lon) {
      fetchNearestLocations(location.lat, location.lon);
    }
  }, [location]);

  const fetchNearestLocations = async (lat: string, lon: string) => {
    try {
      const response = await fetch(`${apiUrl}/Defis/GetNearestLocations?latitude=${lat}&longitude=${lon}`);
      if (!response.ok) {
        throw new Error('Failed to fetch nearest locations.');
      }
      const data = await response.json();
      setNearestLocations(data);
    } catch (error) {
      console.error('Error fetching nearest locations:', error);
    }
  };

  const fetchAddressSuggestions = async (input: string) => {
    try {
      const response = await axios.get('https://nominatim.openstreetmap.org/search', {
        params: {
          q: input,
          format: 'json',
          addressdetails: 1,
          limit: 5,
          countrycodes: 'at'
        },
      });
      setAddressSuggestions(response.data);
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
    }
  };

  const handleAddressInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAddressQuery(value);
    if (value.trim().length > 2) {
      fetchAddressSuggestions(value);
    } else {
      setAddressSuggestions([]); // Clear suggestions if input is too short
    }
  };

  const handleAddressSuggestionClick = (suggestion: any) => {
    setAddressQuery(suggestion.display_name);
    setAddressSuggestions([]);
    setSelectedLocation({
      id: -1, // Use a temporary negative ID for new location
      name: suggestion.display_name,
      street: suggestion.address.road || '',
      houseNumber: suggestion.address.house_number || '',
      city: suggestion.address.city || suggestion.address.town || suggestion.address.village || '',
      postalCode: suggestion.address.postcode || '',
    });
  };

  const handleLocationSelection = (location: Location) => {
    setSelectedLocation(location);
    if (!initialLocation) {
      setInitialLocation({ ...location }); // Set initial location only once
    }
    setAddress(`${location.street} ${location.houseNumber}, ${location.city}, ${location.postalCode}`);
    setCurrentStep('confirmLocation');
    setStatusMessage('Edit the location details if necessary.');
  };

  // Function to check if the location has been changed
  const checkLocationChanges = (currentLocation: Location | null) => {
    if (!initialLocation || !currentLocation) return false;
    return (
      initialLocation.name !== currentLocation.name ||
      initialLocation.street !== currentLocation.street ||
      initialLocation.houseNumber !== currentLocation.houseNumber ||
      initialLocation.city !== currentLocation.city ||
      initialLocation.postalCode !== currentLocation.postalCode
    );
  };

  // Update the isLocationChanged flag whenever the selected location changes
  useEffect(() => {
    if (selectedLocation) {
      setIsLocationChanged(checkLocationChanges(selectedLocation));
    }
  }, [selectedLocation]);

  const reverseGeocode = async (lat: number, lon: number): Promise<string> => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`;
    try {
      const response = await fetch(url, {
        headers: {
          'User-Agent': 'YourApp/1.0'  // Specify your app's name/version
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch address.');
      }
      const data = await response.json();
      return data.display_name || 'Address not found';  // 'display_name' contains the full address
    } catch (error) {
      console.error('Error during reverse geocoding:', error);
      return 'Failed to retrieve address';
    }
  };

  const geocode = async (address: string): Promise<{ lat: string, lon: string }> => {
    const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`;
    try {
      const response = await fetch(url, {
        headers: {
          'User-Agent': 'YourApp/1.0'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch coordinates.');
      }
      const data = await response.json();
      if (data.length > 0) {
        const { lat, lon } = data[0];
        return { lat, lon };  // Return the first result's latitude and longitude
      } else {
        throw new Error('No results found');
      }
    } catch (error) {
      console.error('Error during geocoding:', error);
      return { lat: '0', lon: '0' };  // Return a default value or handle as needed
    }
  };

  const handleRequestCode = async () => {
    setStatusMessage('Code wird gesendet...');  // 'Sending code...'
    try {
      const fullPhoneNumber = countryCode + phoneNumber;
      const response = await fetch(`${apiUrl}/Defis/RequestAuthCode`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Mobile: fullPhoneNumber })
      });
      if (!response.ok) {
        const errorData = await response.json();  // Assuming the server might return JSON with error details
        throw new Error(errorData.message || 'Request failed with status ' + response.status);
      }
      const data = await response.json();
      setSessionId(data.sessionId);  // Ensure this data.sessionId is correctly provided by the backend
      console.log('Session ID:', data.sessionId);
      setCurrentStep('verifyCode');
      //setStatusMessage('Please enter the code you received.');
      setStatusMessage('Bitte geben Sie den erhaltenen Code ein.');

    } catch (error) {
      //setStatusMessage(error instanceof Error ? error.message : 'Failed to send code. Please try again.');
      setStatusMessage('Fehler beim Senden des Codes. Bitte versuchen Sie es erneut.');
    }
  };

  const handleVerifyCode = async () => {
    setStatusMessage('Code wird überprüft...');  // 'Verifying code...'
    try {
      const fullPhoneNumber = countryCode + phoneNumber;
      const response = await fetch(`${apiUrl}/Defis/VerifyAuthCode`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Mobile: fullPhoneNumber, Code: verificationCode })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Verification failed with status ${response.status}`);
      }

      const data = await response.json();
      if (data.sessionId) {
        setSessionId(data.sessionId);
        localStorage.setItem('phoneNumber', fullPhoneNumber);
        localStorage.setItem('sessionId', data.sessionId);
        if (initialLocationEnabled) {
          handleLocationPermission(true);
        } else {
          setCurrentStep('locationPermission');
          //setStatusMessage('Logged in successfully! Do you agree to share your location?');
          setStatusMessage('Erfolgreich eingeloggt! Möchten Sie Ihren Standort teilen?');
        }
      } else {
        throw new Error('Session ID was not returned from the server');
      }
    } catch (error) {
      //setStatusMessage(error instanceof Error ? error.message : 'Verification failed. Please try again.');
      setStatusMessage('Fehler beim Überprüfen des Codes. Bitte versuchen Sie es erneut.');
    }
  };

  const handleLocationPermission = async (permission: boolean): Promise<void> => {
    localStorage.setItem('locationEnabled', String(permission));
    if (permission) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude.toString(), lon: longitude.toString() });
          const addr = await reverseGeocode(latitude, longitude);
          setAddress(addr);
          setCurrentStep('confirmLocation');
          //setStatusMessage(`Found location: ${addr}. Confirm or use different address.`);
          setStatusMessage(`Confirm or use different address.`);
          resolve();
        }, (error) => {
          console.error('Geolocation error:', error);
          if (error.code === error.PERMISSION_DENIED) {
            setStatusMessage('Standortzugriff verweigert. Bitte geben Sie die Adresse manuell ein oder aktivieren Sie die Standortberechtigungen in Ihren Einstellungen.');  // 'Location access denied. Please enter address manually or enable location permissions in your settings.'
          } else {
            setStatusMessage('Standortzugriff fehlgeschlagen. Bitte geben Sie Ihre Adresse ein.');  // 'Location access failed. Please enter your address.'
          }
          setCurrentStep('enterAddress');
          reject(new Error('Location permission denied'));
        });
      });
    } else {
      setStatusMessage('Bitte geben Sie Ihre Adresse manuell ein.');  // 'Please enter your address manually.'
      setCurrentStep('enterAddress');
      return Promise.resolve();
    }
  };

  const handleLocationConfirmation = async (confirm: boolean): Promise<void> => {
    if (confirm) {
      setCurrentStep('uploadPhotos');
      setStatusMessage('Bitte laden Sie Fotos hoch und beantworten Sie die Fragen.');  // 'Please upload photos and answer the questions.'
    } else {
      setCurrentStep('enterAddress');
    }
    return Promise.resolve();
  };

  const handleAddressSubmit = async () => {
    const coords = await geocode(address);
    setLocation({ lat: coords.lat, lon: coords.lon });
    setCurrentStep('confirmLocation');
    setStatusMessage(`Bitte bestätigen Sie den nächstgelegenen Standort für die Adresse: ${address} oder geben Sie eine andere ein.`);
  };

  const handleResetAuth = () => {
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('locationEnabled');
    setSessionId('');
    setPhoneNumber('');
    setCurrentStep('requestCode');
    setStatusMessage('Geben Sie Ihre Telefonnummer ein (intl. Format z.B. +436641234567)');
    setAddress(''); // Clear address
    setSelectedLocation(null); // Clear selected location
    setNearestLocations([]); // Clear nearest locations
  };

  const handleAddNewLocation = async () => {
    setIsNewLocation(true);
    setCurrentStep('enterAddress');
    const addr = await reverseGeocode(parseFloat(location.lat), parseFloat(location.lon));
    const [street, houseNumber, city, postalCode] = addr.split(', '); // Adjust this based on your reverse geocoding response
    setSelectedLocation({
      id: -1, // Use a temporary negative ID for new location
      name: '',
      street: street || '',
      houseNumber: houseNumber || '',
      city: city || '',
      postalCode: postalCode || '',
    });
  };

  const handleSubmit = async () => {
    // Prepare the survey data
    const surveyData = {
      IdAuthCode: parseInt(sessionId, 10),
      Survey: {
        comments,
        ...questionAnswer,
        address: address,
        location: `${location.lat},${location.lon}`,  // Convert location to string
        initialLocation: initialLocation ? JSON.stringify({
          name: initialLocation.name,
          street: initialLocation.street,
          houseNumber: initialLocation.houseNumber,
          city: initialLocation.city,
          postalCode: initialLocation.postalCode,
        }) : null,
        currentLocation: selectedLocation ? {
          name: selectedLocation.name,
          street: selectedLocation.street,
          houseNumber: selectedLocation.houseNumber,
          city: selectedLocation.city,
          postalCode: selectedLocation.postalCode,
        } : null,
        isNewLocation: isNewLocation, // Flag to indicate if the location is new
        isLocationChanged: isLocationChanged, // Flag to indicate if the location was changed
      },
      Img: imagesBase64.toString(),
      submission: "Your submission data here" // Add the required submission field
    };

    const surveyDataString = JSON.stringify(surveyData);

    // Send surveyDataString to the server
    try {
      const response = await fetch(`${apiUrl}/Defis/SubmitSurvey`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: surveyDataString
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit survey');
      }

      const result = await response.json();
      console.log('Submission successful:', result.message);
      setCurrentStep('submitted');
      setStatusMessage('Erfolgreich eingereicht!');
    } catch (error) {
      console.error('Error submitting survey:', error);
      setStatusMessage('Fehlgeschlagen. Bitte versuchen Sie es erneut.');
    }
  };

  const handleInputChange = (field: keyof Location, value: string) => {
    if (selectedLocation) {
      setSelectedLocation({ ...selectedLocation, [field]: value } as Location);
    }
  };

  return (
    <div style={style.container}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        {currentStep === 'requestCode' && (
          <div style={style.questionBlock}>
            <label>Geben Sie Ihre Telefonnummer ein (intl. Format z.B. +436641234567):</label>  {/* <label>Enter your phone number (intl. format e.g. +436641234567):</label> */}
            <div style={{...style.inputGroup, justifyContent: 'center'}}>
              <div style={style.countryCode}>{countryCode}</div>
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value.replace(/^\+43/, ''))}  // Ensure country code isn't duplicated
                placeholder="Phone Number"
                style={{ ...style.input, maxWidth: '250px' }}
              />
            </div>
            <button 
              onClick={() => {
                setIsProcessing(true);
                handleRequestCode().finally(() => setIsProcessing(false));
              }}
              disabled={isProcessing}
              style={style.button}
            >
              Code senden
              {/* Send Code */}
            </button>
          </div>
        )}
        {currentStep === 'verifyCode' && (
          <div style={style.questionBlock}>
            <label>Bitte geben Sie den erhaltenen Code ein:</label>  {/* <label>Please enter the code you received:</label> */}
            <input
              type="tel"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="Verification Code"
              style={style.input}
            />
            <button 
              onClick={() => {
                setIsProcessing(true);
                handleVerifyCode().finally(() => setIsProcessing(false));
              }}
              disabled={isProcessing}
              style={style.button}
            >
              Code überprüfen
              {/* Verify Code */}
            </button>
          </div>
        )}
        {currentStep === 'locationPermission' && (
          <div style={style.questionBlock}>
            <label>Erfolgreich eingeloggt! Möchten Sie Ihren aktuellen Standort verwenden oder die Adresse manuell eingeben?</label>  {/* <label>Logged in successfully! Would you like to use your current location or enter address manually?</label> */}
            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
              <button 
                onClick={() => {
                  setIsProcessing(true);
                  handleLocationPermission(true).finally(() => setIsProcessing(false));
                }}
                disabled={isProcessing}
                style={style.button}
              >
                Ja, Standort verwenden
                {/* Yes, Use Current Location */}
              </button>
              <button 
                onClick={() => {
                  setIsProcessing(true);
                  handleLocationPermission(false).finally(() => setIsProcessing(false));
                }}
                disabled={isProcessing}
                style={style.button}
              >
                Nein, Adresse eingeben
                {/* No, Enter Address */}
              </button>
            </div>
          </div>
        )}
        {currentStep === 'enterAddress' && (
          <div style={style.questionBlock}>
            <label>Bitte geben Sie die Standortdetails ein:</label>
            <input
              type="text"
              value={selectedLocation?.street || ''}
              onChange={(e) => {
                handleInputChange('street', e.target.value);
                handleAddressInputChange(e);
              }}
              placeholder="Straße"
              style={style.input}
            />
            {addressSuggestions.length > 0 && (
              <ul style={{ listStyleType: 'none', padding: 0, margin: '8px 0', border: '1px solid #ccc', borderRadius: '4px' }}>
                {addressSuggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    style={{ padding: '8px', cursor: 'pointer' }}
                    onClick={() => handleAddressSuggestionClick(suggestion)}
                  >
                    {suggestion.display_name}
                  </li>
                ))}
              </ul>
            )}
            <input
              type="text"
              value={selectedLocation?.houseNumber || ''}
              onChange={(e) => handleInputChange('houseNumber', e.target.value)}
              placeholder="Hausnummer"
              style={style.input}
            />
            <input
              type="text"
              value={selectedLocation?.city || ''}
              onChange={(e) => handleInputChange('city', e.target.value)}
              placeholder="Stadt"
              style={style.input}
            />
            <input
              type="text"
              value={selectedLocation?.postalCode || ''}
              onChange={(e) => handleInputChange('postalCode', e.target.value)}
              placeholder="Postleitzahl"
              style={style.input}
            />
            <input
              type="text"
              value={selectedLocation?.name || ''}
              onChange={(e) => handleInputChange('name', e.target.value)}
              placeholder="Standortkennung"
              style={style.input}
            />
            <button onClick={() => handleLocationConfirmation(true)} style={style.button}>
              Bestätigen
            </button>
          </div>
        )}
        {currentStep === 'confirmLocation' && (
          <div style={style.questionBlock}>
            <label>Wählen Sie den nächstgelegenen Standort aus oder fügen Sie einen neuen hinzu:</label>
            <ul>
              {nearestLocations.map((loc) => (
                <li
                  key={loc.id}
                  style={style.locationItem}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = style.locationItemHover.backgroundColor as string)}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '')}
                  onClick={() => handleLocationSelection(loc)}
                >
                  <strong>{loc.name}</strong><br />
                  {loc.street} {loc.houseNumber}, {loc.city}, {loc.postalCode}
                </li>
              ))}
            </ul>
            <button onClick={() => setCurrentStep('enterAddress')} style={style.button}>
              Neuen Standort hinzufügen
            </button>
          </div>
        )}
        {selectedLocation && (currentStep === 'enterAddress' || currentStep === 'confirmLocation') && (
          <div style={style.questionBlock}>
            <label>Bearbeiten Sie die Standortdetails:</label>
            <input
              type="text"
              value={selectedLocation.street}
              onChange={(e) => handleInputChange('street', e.target.value)}
              placeholder="Straße"
              style={style.input}
            />
            <input
              type="text"
              value={selectedLocation.houseNumber}
              onChange={(e) => handleInputChange('houseNumber', e.target.value)}
              placeholder="Hausnummer"
              style={style.input}
            />
            <input
              type="text"
              value={selectedLocation.city}
              onChange={(e) => handleInputChange('city', e.target.value)}
              placeholder="Stadt"
              style={style.input}
            />
            <input
              type="text"
              value={selectedLocation.postalCode}
              onChange={(e) => handleInputChange('postalCode', e.target.value)}
              placeholder="Postleitzahl"
              style={style.input}
            />
            <input
              type="text"
              value={selectedLocation.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
              placeholder="Standortkennung"
              style={style.input}
            />
            <button onClick={() => handleLocationConfirmation(true)} style={style.button}>
              Bestätigen
            </button>
          </div>
        )}
        {(currentStep === 'uploadPhotos' || currentStep === 'submitted') && (
          <Survey
            sessionId={sessionId}
            address={address}
            location={location}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            setStatusMessage={setStatusMessage}
            selectedLocation={selectedLocation}
            isNewLocation={isNewLocation}
            isLocationChanged={isLocationChanged}
          />
        )}
      </div>
      <p style={{ marginTop: '20px', textAlign: 'center', width: '100%' }}>
        {statusMessage}
      </p>
      <div style={style.authInfoContainer}>
        <span>Auth Info: {sessionId ? `Authentifiziert mit ${phoneNumber.startsWith('+43') ? phoneNumber : countryCode + phoneNumber}` : 'Nicht authentifiziert'}</span>  {/* <span>Auth Info: {sessionId ? `Authenticated with ${phoneNumber.startsWith('+43') ? phoneNumber : countryCode + phoneNumber}` : 'Not authenticated'}</span> */}
        <button style={style.resetButton} onClick={handleResetAuth}>
          Authentifizierung zurücksetzen
          {/* Reset Auth Info */}
        </button>
      </div>
    </div>
  );
};

export default DefiCheckField;